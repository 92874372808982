import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  @import url('<https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap>');

  

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body, #root {
    height: 100%;
    background-color: #121212;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }
  a {
    text-decoration: none;

  }
  .vrlps-trigger {
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-weight: lighter;
    color: #fdde68;
    font-size: 25px;

    @media screen and (max-width: 660px) {
      font-size: 18px;
    }
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }

`
